.side-drawer {
  height: 100%;
  background-color: white;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  width: 70%;
  max-width: 400px;
  z-index: 200;
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
}

.side-drawer.open {
  transform: translateX(0);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
  overflow-y: scroll;
  height: 70%;
}

/* Hide scrollbar for IE, Edge add Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll;
  height: 70%;
}
