.notification {
  font-family: "Gill Sans", sans-serif;
  letter-spacing: 1.2px;
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
}

/* table-custom-tender-profile */

.table-custom-tender-profile {
  overflow-x: hidden;
}
.table-custom-tender-profile
  .react-bootstrap-table
  .table
  thead
  tr
  th:first-child {
  width: 15%;
  padding: 10px !important;
}
.table-custom-tender-profile
  .react-bootstrap-table
  .table
  tbody
  tr
  td:first-child {
  width: 15%;
  padding: 10px !important;
}

.table-custom-tender-profile
  .react-bootstrap-table
  .table
  thead
  tr
  th:nth-child(2) {
  width: 30%;
  padding: 10px !important;
}

.table-custom-tender-profile
  .react-bootstrap-table
  .table
  tbody
  tr
  td:nth-child(2) {
  width: 30%;
  padding: 10px !important;
}
.table-custom-tender-profile
  .react-bootstrap-table
  .table
  tbody
  tr
  td:nth-child(2)
  div {
  width: 100% !important;
}

.table-custom-tender-profile
  .react-bootstrap-table
  .table
  thead
  tr
  th:nth-child(3) {
  width: 30%;
  padding: 10px !important;
}
.table-custom-tender-profile
  .react-bootstrap-table
  .table
  tbody
  tr
  td:nth-child(3) {
  width: 30%;
  padding: 10px !important;
}
.table-custom-tender-profile
  .react-bootstrap-table
  .table
  tbody
  tr
  td:nth-child(3)
  div {
  width: 100% !important;
}
.table-custom-tender-profile
  .react-bootstrap-table
  .table
  thead
  tr
  th:nth-child(4) {
  width: 10%;
  padding: 10px !important;
}
.table-custom-tender-profile
  .react-bootstrap-table
  .table
  tbody
  tr
  td:nth-child(4) {
  width: 10%;
  padding: 10px !important;
}

.table-custom-tender-profile
  .react-bootstrap-table
  .table
  thead
  tr
  th:nth-child(5) {
  width: 10%;
  padding: 10px !important;
}
.table-custom-tender-profile
  .react-bootstrap-table
  .table
  tbody
  tr
  td:nth-child(5) {
  width: 10%;
  padding: 10px !important;
}

.table-custom-tender-profile
  .react-bootstrap-table
  .table
  thead
  tr
  th:nth-child(6) {
  width: 5%;
  padding: 10px !important;
}
.table-custom-tender-profile
  .react-bootstrap-table
  .table
  tbody
  tr
  td:nth-child(6) {
  width: 5%;
  padding: 10px !important;
}

/* table-custom-tender-notice */

.table-custom-tender-notice {
  overflow-x: hidden;
}

.table-custom-tender-notice
  .react-bootstrap-table
  .table
  thead
  tr
  th:first-child {
  width: 15%;
  padding: 10px !important;
}
.table-custom-tender-notice
  .react-bootstrap-table
  .table
  tbody
  tr
  td:first-child {
  width: 15%;
  padding: 10px !important;
}

.table-custom-tender-notice
  .react-bootstrap-table
  .table
  thead
  tr
  th:nth-child(2) {
  width: 30%;
  padding: 10px !important;
}

.table-custom-tender-notice
  .react-bootstrap-table
  .table
  tbody
  tr
  td:nth-child(2) {
  width: 30%;
  padding: 10px !important;
}
.table-custom-tender-notice
  .react-bootstrap-table
  .table
  tbody
  tr
  td:nth-child(2)
  div {
  width: 100% !important;
}

.table-custom-tender-notice
  .react-bootstrap-table
  .table
  thead
  tr
  th:nth-child(3) {
  width: 30%;
  padding: 10px !important;
}
.table-custom-tender-notice
  .react-bootstrap-table
  .table
  tbody
  tr
  td:nth-child(3) {
  width: 30%;
  padding: 10px !important;
}
.table-custom-tender-notice
  .react-bootstrap-table
  .table
  tbody
  tr
  td:nth-child(3)
  div {
  width: 100% !important;
}
.table-custom-tender-notice
  .react-bootstrap-table
  .table
  thead
  tr
  th:nth-child(4) {
  width: 10%;
  padding: 10px !important;
}
.table-custom-tender-notice
  .react-bootstrap-table
  .table
  tbody
  tr
  td:nth-child(4) {
  width: 10%;
  padding: 10px !important;
}

.table-custom-tender-notice
  .react-bootstrap-table
  .table
  thead
  tr
  th:nth-child(5) {
  width: 10%;
  padding: 10px !important;
}
.table-custom-tender-notice
  .react-bootstrap-table
  .table
  tbody
  tr
  td:nth-child(5) {
  width: 10%;
  padding: 10px !important;
}

.table-custom-tender-notice
  .react-bootstrap-table
  .table
  thead
  tr
  th:nth-child(6) {
  width: 5%;
  padding: 10px !important;
}
.table-custom-tender-notice
  .react-bootstrap-table
  .table
  tbody
  tr
  td:nth-child(6) {
  width: 5%;
  padding: 10px !important;
}
/* table-custom-tender-results */

.table-custom-tender-results {
  overflow-x: hidden;
}

.table-custom-tender-results
  .react-bootstrap-table
  .table
  thead
  tr
  th:first-child {
  width: 15%;
  padding: 10px !important;
}
.table-custom-tender-results
  .react-bootstrap-table
  .table
  tbody
  tr
  td:first-child {
  width: 15%;
  padding: 10px !important;
}

.table-custom-tender-results
  .react-bootstrap-table
  .table
  thead
  tr
  th:nth-child(2) {
  width: 30%;
  padding: 10px !important;
}

.table-custom-tender-results
  .react-bootstrap-table
  .table
  tbody
  tr
  td:nth-child(2) {
  width: 30%;
  padding: 10px !important;
}
.table-custom-tender-results
  .react-bootstrap-table
  .table
  tbody
  tr
  td:nth-child(2)
  div {
  width: 100% !important;
}

.table-custom-tender-results
  .react-bootstrap-table
  .table
  thead
  tr
  th:nth-child(3) {
  width: 30%;
  padding: 10px !important;
}
.table-custom-tender-results
  .react-bootstrap-table
  .table
  tbody
  tr
  td:nth-child(3) {
  width: 30%;
  padding: 10px !important;
}
.table-custom-tender-results
  .react-bootstrap-table
  .table
  tbody
  tr
  td:nth-child(3)
  div {
  width: 100% !important;
}
.table-custom-tender-results
  .react-bootstrap-table
  .table
  thead
  tr
  th:nth-child(4) {
  width: 20%;
  padding: 10px !important;
}
.table-custom-tender-results
  .react-bootstrap-table
  .table
  tbody
  tr
  td:nth-child(4) {
  width: 100%;
  padding: 10px !important;
}

.table-custom-tender-results
  .react-bootstrap-table
  .table
  thead
  tr
  th:nth-child(5) {
  width: 10%;
  padding: 10px !important;
}
.table-custom-tender-results
  .react-bootstrap-table
  .table
  tbody
  tr
  td:nth-child(5) {
  width: 10%;
  padding: 10px !important;
}

/* table-custom-user-manage */

.table-custom-user-manage {
  overflow-x: hidden;
}

.table-custom-user-manage
  .react-bootstrap-table
  .table
  thead
  tr
  th:first-child {
  width: 15%;
  padding: 10px !important;
}
.table-custom-user-manage
  .react-bootstrap-table
  .table
  tbody
  tr
  td:first-child {
  width: 15%;
  padding: 10px !important;
}

.table-custom-user-manage
  .react-bootstrap-table
  .table
  thead
  tr
  th:nth-child(2) {
  width: 15%;
  padding: 10px !important;
}

.table-custom-user-manage
  .react-bootstrap-table
  .table
  tbody
  tr
  td:nth-child(2) {
  width: 15%;
  padding: 10px !important;
}
/* .table-custom-user-manage .react-bootstrap-table .table tbody tr td:nth-child(2) div{
  width: 100% !important;
} */

.table-custom-user-manage
  .react-bootstrap-table
  .table
  thead
  tr
  th:nth-child(3) {
  width: 10%;
  padding: 10px !important;
}
.table-custom-user-manage
  .react-bootstrap-table
  .table
  tbody
  tr
  td:nth-child(3) {
  width: 10%;
  padding: 10px !important;
}
.table-custom-user-manage
  .react-bootstrap-table
  .table
  tbody
  tr
  td:nth-child(4)
  div {
  width: 100% !important;
}
.table-custom-user-manage
  .react-bootstrap-table
  .table
  thead
  tr
  th:nth-child(4) {
  width: 30%;
  padding: 10px !important;
}
.table-custom-user-manage
  .react-bootstrap-table
  .table
  tbody
  tr
  td:nth-child(4) {
  width: 30%;
  padding: 10px !important;
}

.table-custom-user-manage
  .react-bootstrap-table
  .table
  thead
  tr
  th:nth-child(5) {
  width: 10%;
  padding: 10px !important;
}
.table-custom-user-manage
  .react-bootstrap-table
  .table
  tbody
  tr
  td:nth-child(5) {
  width: 10%;
  padding: 10px !important;
}

.table-custom-user-manage
  .react-bootstrap-table
  .table
  thead
  tr
  th:nth-child(6) {
  width: 20%;
  padding: 10px !important;
}
.table-custom-user-manage
  .react-bootstrap-table
  .table
  tbody
  tr
  td:nth-child(6) {
  width: 20%;
  padding: 10px !important;
}
/* table-custom-key-manage */
.table-custom-key-manage {
  overflow-x: hidden;
}

/*  table-custom-document-type-manage */
.table-custom-document-type-manage {
  overflow-x: hidden;
}

/*  table-custom-document-manage */
.table-custom-document-manage {
  overflow-x: hidden;
}
